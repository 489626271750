var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Help" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "text" }, [
        _c("h1", [_vm._v(" " + _vm._s(_vm.$t("MENU.TOOLTIP.HELP")))])
      ])
    ]),
    _c("main", [
      _c("iframe", {
        attrs: {
          src: _vm.urlPdf,
          frameborder: "0",
          height: "95%",
          width: "100%"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }